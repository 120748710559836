// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-docs-api-index-js": () => import("./../../../src/pages/docs/api/index.js" /* webpackChunkName: "component---src-pages-docs-api-index-js" */),
  "component---src-pages-docs-api-libraries-node-mdx": () => import("./../../../src/pages/docs/api/libraries/node.mdx" /* webpackChunkName: "component---src-pages-docs-api-libraries-node-mdx" */),
  "component---src-pages-docs-api-reference-mdx": () => import("./../../../src/pages/docs/api/reference.mdx" /* webpackChunkName: "component---src-pages-docs-api-reference-mdx" */),
  "component---src-pages-docs-box-api-index-js": () => import("./../../../src/pages/docs/box/api/index.js" /* webpackChunkName: "component---src-pages-docs-box-api-index-js" */),
  "component---src-pages-docs-box-api-reference-mdx": () => import("./../../../src/pages/docs/box/api/reference.mdx" /* webpackChunkName: "component---src-pages-docs-box-api-reference-mdx" */),
  "component---src-pages-docs-box-index-js": () => import("./../../../src/pages/docs/box/index.js" /* webpackChunkName: "component---src-pages-docs-box-index-js" */),
  "component---src-pages-docs-index-js": () => import("./../../../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

